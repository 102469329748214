import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import OrderItem from '@/components/items/OrderItem.vue';
import { formatMoney } from '@/utils/utils';
import { getOrderedServices } from '@/api/ordered-services.js';
let OrderHistoryPage = class OrderHistoryPage extends Vue {
    constructor() {
        super(...arguments);
        this.orderedServices = [];
        this.formatMoney = formatMoney;
    }
    async mounted() {
        this.orderedServices = await getOrderedServices();
    }
    get orderedServicesDates() {
        return this.orderedServices.dates?.reverse();
    }
    formattedData(date) {
        return this.$date(new Date(date), 'dd MMMM yyyy');
    }
    reduceSumm(arr) {
        return arr.reduce((sum, current) => sum + current.price, 0);
    }
};
OrderHistoryPage = __decorate([
    Component({
        components: {
            OrderItem
        },
    })
], OrderHistoryPage);
export default OrderHistoryPage;
